<template>
  <div class="links">
    <img class="close-links" src="../assets/tipp/tipp-cross.svg" @click="$router.go(-1)">
    <div class="tipp-title-name" v-if="!isFront">
      {{tipptitle}}
    </div>
    <div class="textsheet">
      <h2>{{ $t('footer.links') }}</h2>
      <p>
        <br>
        <span v-for="link in links" :key="link.text">
          <a :href="link.link" target="_blank">{{ link.text }}</a><br><br><br>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import data from "@/data/data-tree.js";

export default {
  name: "links",
  data() {
    return {
      links: [],
      tipptitle: ""
    }
  },
  mounted() {
    this.links = data['tipps' + this.$i18n.locale][this.$route.params.id].links
    this.tipptitle = data['tipps' + this.$i18n.locale][this.$route.params.id].title
  },
}
</script>

<style lang="scss" scoped>
.links {
  width: 100%;
  min-height: 95vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 70px;

  @include tablet-down {
    padding: 25px;
    padding-top: 40px;
  }
}

.tipp-title-name {
  position: absolute;
  top: 25px;
  left: 72px;
  font-size: 17px;
  font-family: "HKSentiments-bold", sans-serif;

  @include tablet-down {
    top: 70px;
  }
}

.close-links {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  cursor: pointer;

  @include tablet-down {
    z-index: 2;
  }

  @include mobile-down {
    width: 35px;
  }
}
</style>
