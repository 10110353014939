<template>
    <svg id="eiypiy41xn2r1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 79.950000 90.100000" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">

        <g id="eiypiy41xn2r8">
            <path id="eiypiy41xn2r9"
                  d="M39.980000,66.930000C22.450000,66.930000,8.180000,52.670000,8.180000,35.130000C8.180000,17.600000,22.450000,3.330000,39.980000,3.330000C57.510000,3.330000,71.780000,17.600000,71.780000,35.130000C71.780000,52.660000,57.510000,66.930000,39.980000,66.930000ZM39.980000,7.330000C24.650000,7.330000,12.180000,19.800000,12.180000,35.130000C12.180000,50.460000,24.650000,62.930000,39.980000,62.930000C55.310000,62.930000,67.780000,50.460000,67.780000,35.130000C67.780000,19.800000,55.300000,7.330000,39.980000,7.330000Z"
                  fill="rgb(29,29,27)" stroke="none" stroke-width="1"/>
        </g>
        <g id="eiypiy41xn2r10">
            <g id="eiypiy41xn2r11_to" transform="translate(39,40)">
                <g id="eiypiy41xn2r11_tr" transform="">
                    <rect id="eiypiy41xn2r11" width="32.016700" height="20.033300" rx="0" ry="0"
                          transform="translate(-16.008350,-10.016650)" fill="rgb(255,161,158)" stroke="none"
                          stroke-width="1"/>
                </g>
            </g>
        </g>
        <g id="eiypiy41xn2r12">
            <g id="eiypiy41xn2r13_to" transform="translate(43,35)">
                <g id="eiypiy41xn2r13_tr" transform="">
                    <path id="eiypiy41xn2r13"
                          d="M60.600000,46.580000L24.590000,46.580000L24.590000,22.530000L60.600000,22.530000L60.600000,46.580000ZM28.590000,42.580000L56.600000,42.580000L56.600000,26.530000L28.590000,26.530000L28.590000,42.580000Z"
                          transform="translate(-42.595000,-34.555000)" fill="rgb(29,29,27)" stroke="none"
                          stroke-width="1"/>
                </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "MenuLinks"
    }
</script>

<style scoped>
    #eiypiy41xn2r1 {
        pointer-events: all
    }

    #eiypiy41xn2r1:hover #eiypiy41xn2r11_to {
        animation: eiypiy41xn2r11_to__to 3000ms linear 1 normal forwards
    }

    @keyframes eiypiy41xn2r11_to__to {
        0% {
            transform: translate(39.975000px, 35.130000px)
        }
        36.666667% {
            transform: translate(39.975000px, 35.130000px)
        }
        43.333333% {
            transform: translate(38.198350px, 39.116650px)
        }
        100% {
            transform: translate(38.198350px, 39.116650px)
        }
    }

    #eiypiy41xn2r1:hover #eiypiy41xn2r11_tr {
        animation: eiypiy41xn2r11_tr__tr 3000ms linear 1 normal forwards
    }

    @keyframes eiypiy41xn2r11_tr__tr {
        0% {
            transform: rotate(90deg)
        }
        16.666667% {
            transform: rotate(90deg)
        }
        23.333333% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(0deg)
        }
    }

    #eiypiy41xn2r1:hover #eiypiy41xn2r11 {
        animation: eiypiy41xn2r11_c_o 1500ms linear 1 normal forwards
    }

    @keyframes eiypiy41xn2r11_c_o {
        0% {
            opacity: 0
        }
        20% {
            opacity: 0
        }
        23.333333% {
            opacity: 1
        }
        100% {
            opacity: 1
        }
    }

    #eiypiy41xn2r1:hover #eiypiy41xn2r13_to {
        animation: eiypiy41xn2r13_to__to 3000ms linear 1 normal forwards
    }

    @keyframes eiypiy41xn2r13_to__to {
        0% {
            transform: translate(39.980000px, 35.130000px)
        }
        36.666667% {
            transform: translate(39.980000px, 35.130000px)
        }
        43.333333% {
            transform: translate(42.595000px, 34.555000px)
        }
        100% {
            transform: translate(42.595000px, 34.555000px)
        }
    }

    #eiypiy41xn2r1:hover #eiypiy41xn2r13_tr {
        animation: eiypiy41xn2r13_tr__tr 3000ms linear 1 normal forwards
    }

    @keyframes eiypiy41xn2r13_tr__tr {
        0% {
            transform: rotate(90deg)
        }
        16.666667% {
            transform: rotate(90deg)
        }
        23.333333% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(0deg)
        }
    }
</style>