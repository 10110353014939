import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from "./locals/i18n";
import VueYoutube from 'vue-youtube'

Vue.use(VueYoutube)
Vue.config.productionTip = false

new Vue({
  data() {
    return {
      intro: true
    }
  },
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    if (this.$route.query.lang) {
      if (this.$route.query.lang == 'de')
        this.$i18n.locale = 'de'
      if (this.$route.query.lang == 'fr')
        this.$i18n.locale = 'fr'
      if (this.$route.query.lang == 'it')
        this.$i18n.locale = 'it'
    }
    console.log(this.$route.name)
    if (this.$route.name === "Home" && this.intro) {
      this.intro = false
      this.$router.push('/intro')
    }
  }
}).$mount('#app')
