<template>
    <div class="menu-wrapper" v-if="render">
      <component :is="compName"></component>
      <div class="label">
        <span>{{menName}}</span>
      </div>
    </div>
</template>

<script>
import MenuAnleitung from "./MenuAnleitung";
import MenuIntro from "./MenuIntro";
import MenuGrundlagen from "./MenuGrundlagen";
import MenuSuche from "./MenuSuche";
import MenuLinks from "@/components/animatedMenus/MenuLinks";
import MenuTippClip from "@/components/animatedMenus/MenuTippClip";
import MenuBlackstory from "@/components/animatedMenus/MenuBlackstory";
import MenuQuiz from "@/components/animatedMenus/MenuQuiz";
import MenuLeitfaden from "@/components/animatedMenus/MenuLeitfaden";
import MenuFeedback from "@/components/animatedMenus/MenuFeedback";
import MenuHandout from "@/components/animatedMenus/MenuHandout";

  export default {
    name: "MenuWrapper",
    components: {
      MenuSuche,
      MenuGrundlagen,
      MenuIntro,
      MenuAnleitung,
      MenuLinks,
      MenuTippClip,
      MenuBlackstory,
      MenuQuiz,
      MenuLeitfaden,
      MenuFeedback,
      MenuHandout
    },
    data() {
      return {
        render: true
      }
    },
    props: {
      compName: {
        type: String,
        default: ''
      },
      menName: {
        type: String,
        default: 'undefiniert'
      }
    },
    mounted() {
      if (this.compName === '') {
        this.render = false;
      }
    }
  }
</script>

<style scoped lang="scss">

.menu-wrapper {

  svg {
    width: 100px;
    margin-bottom: -20px;
  }

  .label {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    span {
      width: 100%;
      text-align: center;
      margin: 0 auto;
    }
  }

}

</style>
