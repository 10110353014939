<template>
        <div id="tipp" :class="[ tippClass, gridClass]">
            <div class="tipp-outer" @click="routeToTipp">
                <div class="tipp-inner" :style="'border-top: solid 5px' + color">
                    {{title}}
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "TippTile",
        props: {
            title: {
                type: String
            },
            color: {
                type: String
            },
            tippClass: {
                type: String
            },
            inde: {
                type: Number
            }
        },
        mounted() {

        },
        methods: {
            routeToTipp() {
                this.$router.push('/tipp/' + this.inde)
            }
        },
        computed: {
            gridClass() {
                return 'e' + (this.inde + 1);
            }
        },
    }
</script>

<style lang="scss" scoped>
    #tipp {
        width: 90%;
        font-family: "HKSentiments-black", sans-serif;
        font-size: 30px;
        transition: all 0.6s ease-out;
        cursor: pointer;
        background-color: white;
        box-shadow: rgba(0,0,0,0.0) 0 0 40px;
        position: relative;


        @include desktop-large-down {
            font-size: 25px;
            margin-bottom: 15px;
        }

        @include tablet-down {
            display: block;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        .tipp-inner {
            padding-top: 10px;
            padding-left: 4px;
            margin: 6px;
            background-color: white;
            position: relative;

            @media (max-width: 1700px) {
              font-size: 25px;
            }
        }

        .tipp-outer {
            width: 100%;
            height: 100%;
            position: relative;
        }

        &:hover {
            z-index: 10;
            box-shadow: rgba(0,0,0,0.6) 0 0 40px;
            transform: scale(1.4);

            @include desktop-large-up {
                &.right-border {
                    transform: scale(1.4) translate(-15%);
                }

                &.left-border {
                    transform: scale(1.4) translate(15%);
                }
            }

            @include desktop-large-down {
                transform: scale(1.2);
            }

            .tipp-inner {
                z-index: 12;
            }

            .tipp-outer {

                .sub {
                    opacity: 1;
                    top: 80%;
                    right: 3%;
                    transform: rotateX(0deg);
                }
            }
        }
    }
</style>