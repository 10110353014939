<template>
  <svg id="a6ipa7z7q8sl1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <g id="a6ipa7z7q8sl2_to" transform="translate(37,46)">
      <g id="a6ipa7z7q8sl2_tr" transform="rotate(0)">
        <g id="a6ipa7z7q8sl2" transform="translate(-37.850000,-46.150000)">
          <g id="a6ipa7z7q8sl3">
            <g id="a6ipa7z7q8sl4_ts" transform="translate(6.200000,46.150000) scale(1,1)">
              <rect id="a6ipa7z7q8sl4" width="63.300000" height="13.300000" rx="0" ry="0"
                    transform="translate(0,-6.650000)" fill="rgb(199,178,153)" stroke="none" stroke-width="1"/>
            </g>
          </g>
          <g id="a6ipa7z7q8sl5">
            <g id="a6ipa7z7q8sl6_ts" transform="translate(10.500000,24.450000) scale(1,1)">
              <path id="a6ipa7z7q8sl6"
                    d="M10.500000,31.500000L69.600000,31.500000L69.600000,18.200000L19.300000,18.200000C15.600000,22,12.600000,26.500000,10.500000,31.500000Z"
                    transform="translate(-10.500000,-24.450000)" fill="rgb(199,178,153)" stroke="none"
                    stroke-width="1"/>
            </g>
          </g>
          <g id="a6ipa7z7q8sl7">
            <g id="a6ipa7z7q8sl8_ts" transform="translate(8.400000,67.450000) scale(1,1)">
              <path id="a6ipa7z7q8sl8"
                    d="M14.500000,74.100000L69.500000,74.100000L69.500000,60.800000L8.400000,60.800000C9.700000,65.600000,11.800000,70,14.500000,74.100000Z"
                    transform="translate(-8.400000,-67.450000)" fill="rgb(199,178,153)" stroke="none" stroke-width="1"/>
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="a6ipa7z7q8sl9">

    </g>
    <g id="a6ipa7z7q8sl18">
      <path id="a6ipa7z7q8sl19"
            d="M50.900000,93.700000C25.500000,93.700000,4.900000,73.100000,4.900000,47.700000C4.900000,22.300000,25.500000,1.700000,50.900000,1.700000C76.300000,1.700000,96.900000,22.300000,96.900000,47.700000C96.900000,73.100000,76.300000,93.700000,50.900000,93.700000ZM50.900000,5.700000C27.700000,5.700000,8.900000,24.500000,8.900000,47.700000C8.900000,70.900000,27.700000,89.700000,50.900000,89.700000C74.100000,89.700000,92.900000,70.900000,92.900000,47.700000C92.900000,24.500000,74,5.700000,50.900000,5.700000Z"
            fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    </g>
    <g id="a6ipa7z7q8sl20">
      <path id="a6ipa7z7q8sl21"
            d="M70.100000,80.300000L57.200000,71L22.500000,71L22.500000,21.500000L79.300000,21.500000L79.300000,71L69.700000,71L70.100000,80.300000ZM26.500000,67L58.500000,67L65.700000,72.200000L65.500000,67L75.200000,67L75.200000,25.500000L26.500000,25.500000L26.500000,67Z"
            fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuFeedback"
}
</script>

<style scoped>

#a6ipa7z7q8sl1 {
  pointer-events: all
}

#a6ipa7z7q8sl1:hover #a6ipa7z7q8sl2_to {
  animation: a6ipa7z7q8sl2_to__to 3000ms linear 1 normal forwards
}

@keyframes a6ipa7z7q8sl2_to__to {
  0% {
    transform: translate(49px, 60px);
    animation-timing-function: cubic-bezier(0.420000, 0, 1, 1)
  }
  23.333333% {
    transform: translate(40px, 56px);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  50% {
    transform: translate(37.850000px, 46.150000px)
  }
  100% {
    transform: translate(37.850000px, 46.150000px)
  }
}

#a6ipa7z7q8sl1:hover #a6ipa7z7q8sl2_tr {
  animation: a6ipa7z7q8sl2_tr__tr 3000ms linear 1 normal forwards
}

@keyframes a6ipa7z7q8sl2_tr__tr {
  0% {
    transform: rotate(-90deg);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  50% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(0deg)
  }
}

#a6ipa7z7q8sl1:hover #a6ipa7z7q8sl4_ts {
  animation: a6ipa7z7q8sl4_ts__ts 3000ms linear 1 normal forwards
}

@keyframes a6ipa7z7q8sl4_ts__ts {
  0% {
    transform: translate(6.200000px, 46.150000px) scale(0, 1)
  }
  16.666667% {
    transform: translate(6.200000px, 46.150000px) scale(0, 1)
  }
  43.333333% {
    transform: translate(6.200000px, 46.150000px) scale(1, 1)
  }
  100% {
    transform: translate(6.200000px, 46.150000px) scale(1, 1)
  }
}

#a6ipa7z7q8sl1:hover #a6ipa7z7q8sl6_ts {
  animation: a6ipa7z7q8sl6_ts__ts 3000ms linear 1 normal forwards
}

@keyframes a6ipa7z7q8sl6_ts__ts {
  0% {
    transform: translate(10.500000px, 24.450000px) scale(0, 1)
  }
  6.666667% {
    transform: translate(10.500000px, 24.450000px) scale(0, 1)
  }
  33.333333% {
    transform: translate(10.500000px, 24.450000px) scale(1, 1)
  }
  100% {
    transform: translate(10.500000px, 24.450000px) scale(1, 1)
  }
}

#a6ipa7z7q8sl1:hover #a6ipa7z7q8sl8_ts {
  animation: a6ipa7z7q8sl8_ts__ts 3000ms linear 1 normal forwards
}

@keyframes a6ipa7z7q8sl8_ts__ts {
  0% {
    transform: translate(8.400000px, 67.450000px) scale(0, 1)
  }
  33.333333% {
    transform: translate(8.400000px, 67.450000px) scale(0, 1)
  }
  50% {
    transform: translate(8.400000px, 67.450000px) scale(1, 1)
  }
  100% {
    transform: translate(8.400000px, 67.450000px) scale(1, 1)
  }
}

</style>
