<template>
  <div class="home">
    <Header></Header>
    <TippGrid></TippGrid>
    <Footer></Footer>
    <LanguageSwitch class="lang-switch"></LanguageSwitch>
    <the-cookie-banner></the-cookie-banner>
  </div>
</template>

<script>
import TippGrid from "../components/TippGrid";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LanguageSwitch from "../components/LanguageSwitch";
import TheCookieBanner from "@/components/TheCookieBanner";

export default {
  name: 'Home',
  components: {
    TheCookieBanner,
    Header,
    Footer,
    TippGrid,
    LanguageSwitch
  }
}
</script>

<style lang="scss" scoped>

.home {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 25px;
  padding-top: 0px;

  @include tablet-down {
    padding-top: 70px;
  }
}

.lang-switch {
  position: absolute;
  top: 0;
  right: 2%;
}


</style>