import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
    {
      path: '/tipp/:id',
      name: 'Tipp',
      component: () => import(/* webpackChunkName: "tipp-view" */ '../views/TippView.vue')
    },
    {
      path: '/blackstory/:id',
      name: 'Blackstory',
      component: () => import(/* webpackChunkName: "blackstory" */ '../views/Blackstory.vue')
    },
    {
      path: '/tippclip/:id',
      name: 'TippClip',
      component: () => import(/* webpackChunkName: "tipp-clip" */ '../views/TippClip.vue')
    },
    {
      path: '/links/:id',
      name: 'Links',
      component: () => import(/* webpackChunkName: "links" */ '../views/Links.vue')
    },
    {
      path: '/intro',
      name: 'Intro',
      component: () => import(/* webpackChunkName: "intro" */ '../views/Intro.vue')
    },
    {
      path: '/suche',
      name: 'Suche',
      component: () => import(/* webpackChunkName: "suche" */ '../views/Suche.vue')
    },
    {
      path: '/grundlagen/:page?',
      name: 'Grundlagen',
      component: () => import(/* webpackChunkName: "grundlagen" */ '../views/Grundlagen.vue')
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: () => import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue')
    },
    {
      path: '/datenschutz',
      name: 'Datenschutz',
      component: () => import(/* webpackChunkName: "impressum" */ '../views/Datenschutz.vue')
    },
    {
      path: '/leitfaden',
      name: 'Leitfaden',
      component: () => import(/* webpackChunkName: "impressum" */ '../views/Leitfaden.vue')
    },
    {
      path: '/handout',
      name: 'Handout',
      component: () => import(/* webpackChunkName: "impressum" */ '../views/Handout.vue')
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: () => import(/* webpackChunkName: "feedback" */ '../views/Feedback.vue')
    },{
      path: '/quiz/de',
      redirect: '/quiz/index.html#/de/start'
    },{
      path: '/quiz/fr',
      redirect: '/quiz/index.html#/fr/start'
    },{
      path: '/quiz/it',
      redirect: '/quiz/index.html#/it/start'
    }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
