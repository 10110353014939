<template>
    <div class="tip-grid">
        <TippTile v-for="(tipp, ind) in tippArray" :key="ind" :title="tipp.title" :color="tipp.color"
              :tippClass="tipp.class" :inde="ind"></TippTile>
    </div>
</template>

<script>

import data from "@/data/data-tree.js";
    import TippTile from "./TippTile";

    export default {
        name: "TippGrid",
        components: {TippTile},
        computed: {
            tippArray() {
                return data['tipps' + this.$i18n.locale];
            }
        }
    }
</script>

<style lang="scss" scoped>

    .tip-grid {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        /*display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        padding-bottom: 25px;*/

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 33% 33% 33%;
        gap: 1px 1px;

    /*.e1 { grid-area: 1 / 1 / 2 / 2; }

    .e2 { grid-area: 1 / 2 / 2 / 3; }

    .e3 { grid-area: 1 / 3 / 2 / 4; }

    .e4 { grid-area: 1 / 4 / 2 / 5; }

    .e5 { grid-area: 2 / 1 / 3 / 2; }

    .e6 { grid-area: 2 / 2 / 3 / 3; }

    .e7 { grid-area: 2 / 3 / 3 / 4; }

    .e8 { grid-area: 2 / 4 / 3 / 5; }

    .e9 { grid-area: 3 / 1 / 4 / 2; }

    .e10 { grid-area: 3 / 2 / 4 / 3; }

    .e11 { grid-area: 3 / 3 / 4 / 4; }

    .e12 { grid-area: 3 / 4 / 4 / 5; }*/

    @include desktop-large-down {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 25% 25% 25% 25%;
    }

    @include tablet-down {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    @include mobile-down {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    }


    @media all and (-ms-high-contrast:none) {
        .tip-grid {

            width: 100%;
            height: 100%;
            display: -ms-grid;
            -ms-grid-columns: 1fr 1fr 1fr 1fr;
            -ms-grid-rows: 1fr 1fr 1fr;
        }

        .e1 {
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .e2 {
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        .e3 {
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }

        .e4 {
            -ms-grid-row: 1;
            -ms-grid-row-span: 1;
            -ms-grid-column: 4;
            -ms-grid-column-span: 1;
        }

        .e5 {
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .e6 {
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        .e7 {
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }

        .e8 {
            -ms-grid-row: 2;
            -ms-grid-row-span: 1;
            -ms-grid-column: 4;
            -ms-grid-column-span: 1;
        }

        .e9 {
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            -ms-grid-column: 1;
            -ms-grid-column-span: 1;
        }

        .e10 {
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            -ms-grid-column: 2;
            -ms-grid-column-span: 1;
        }

        .e11 {
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            -ms-grid-column: 3;
            -ms-grid-column-span: 1;
        }

        .e12 {
            -ms-grid-row: 3;
            -ms-grid-row-span: 1;
            -ms-grid-column: 4;
            -ms-grid-column-span: 1;
        }

    }

</style>