<template>
  <div class="cookie" v-if="isNotAccepted">
    <span v-html="$t('home.cookie')"></span>
    <div class="button" @click="einv">Einverstanden</div>
  </div>
</template>

<script>
import Links from "@/views/Links";
export default {
name: "TheCookieBanner",
  components: {Links},
  data() {
    return {
      isNotAccepted: true
    }
  },
  mounted() {
    if (localStorage.getItem('coo') === '1') {
      this.isNotAccepted = false
    }
  },
  methods: {
    einv() {
      this.isNotAccepted = false;
      localStorage.setItem('coo', "1")
    }
  },
}
</script>

<style lang="scss" scoped>

.cookie {
  position: absolute;
  width: 70%;
  height: 70vh;
  top: 15vh;
  left: 15vh;
  background-color: black;
  color: white;
  font-family: "HKSentiments-bold", sans-serif;
  z-index: 100;
  padding: 80px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include tablet-down {
    width: 90%;
    left: 5%;
    top: 80px;
    height: auto;
    padding: 30px;
    font-size: 20px;
    position: fixed;
  }

  .button {
    margin-top: 20px;
    padding: 8px;
    background-color: white;
    width: max-content;
    color: black;
    cursor: pointer;
  }
}

</style>