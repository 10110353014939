<template>
    <svg id="ev0tmtdx8qpf1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <defs>
            <clipPath id="ev0tmtdx8qpf2">
                <circle id="ev0tmtdx8qpf3" r="43.700000" transform="matrix(1 0 0 1 52 48.75000000000000)" fill="none"
                        stroke="none" stroke-width="1"/>
            </clipPath>
        </defs>
        <g id="ev0tmtdx8qpf4" clip-path="url(#ev0tmtdx8qpf2)">
            <g id="ev0tmtdx8qpf5_to" transform="">
                <path id="ev0tmtdx8qpf5"
                      d="M65.220000,7.620000C65.220000,7.620000,65.220000,63.620000,84.090000,79.080000C84.090000,79.080000,103.590000,58.850000,94.410000,36.230000C89.188307,22.978207,78.573909,12.574716,65.220000,7.620000Z"
                      transform="translate(0,0)" fill="rgb(253,159,156)" stroke="none" stroke-width="1"/>
            </g>
            <g id="ev0tmtdx8qpf6_to" transform="">
                <path id="ev0tmtdx8qpf6"
                      d="M38.780000,7.620000C38.780000,7.620000,38.780000,63.620000,19.910000,79.080000C19.910000,79.080000,0.410000,58.850000,9.590000,36.230000C14.811693,22.978207,25.426091,12.574716,38.780000,7.620000Z"
                      transform="translate(0,0)" fill="rgb(253,159,156)" stroke="none" stroke-width="1"/>
            </g>
        </g>
        <path id="ev0tmtdx8qpf12"
              d="M52,94.750000C26.594902,94.750000,6,74.155098,6,48.750000C6,23.344902,26.594902,2.750000,52,2.750000C77.405098,2.750000,98,23.344902,98,48.750000C97.966945,74.141394,77.391394,94.716945,52,94.750000ZM52,6.750000C28.804041,6.750000,10,25.554041,10,48.750000C10,71.945959,28.804041,90.750000,52,90.750000C75.195959,90.750000,94,71.945959,94,48.750000C94,25.554041,75.195959,6.750000,52,6.750000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
        <g id="ev0tmtdx8qpf13_tr" transform="translate(52.035000,48.750000) rotate(0)">
            <g id="ev0tmtdx8qpf13_ts" transform="scale(1,1)">
                <g id="ev0tmtdx8qpf13" transform="translate(-51.535000,-48.250000)">
                    <rect id="ev0tmtdx8qpf14" width="4" height="52.266700" rx="0" ry="0"
                          transform="matrix(1 0 0 1 27.21000000000000 22.62000000000000)" fill="rgb(0,0,0)"
                          stroke="none" stroke-width="1"/>
                    <path id="ev0tmtdx8qpf15"
                          d="M76,77L27.070000,48.740000L76,20.500000ZM35.070000,48.740000L72,70.060000L72,27.430000Z"
                          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "MenuIntro"
    }
</script>

<style scoped>
    #ev0tmtdx8qpf1 {
        pointer-events: all
    }

    #ev0tmtdx8qpf1:hover #ev0tmtdx8qpf5_to {
        animation: ev0tmtdx8qpf5_to__to 3000ms linear 1 normal forwards
    }

    @keyframes ev0tmtdx8qpf5_to__to {
        0% {
            transform: translate(32.780000px, -7.620000px)
        }
        33.333333% {
            transform: translate(32.780000px, -7.620000px)
        }
        50% {
            transform: translate(0px, 0px)
        }
        100% {
            transform: translate(0px, 0px)
        }
    }

    #ev0tmtdx8qpf1:hover #ev0tmtdx8qpf6_to {
        animation: ev0tmtdx8qpf6_to__to 3000ms linear 1 normal forwards
    }

    @keyframes ev0tmtdx8qpf6_to__to {
        0% {
            transform: translate(-28.487346px, -7.620000px)
        }
        33.333333% {
            transform: translate(-28.487346px, -7.620000px)
        }
        50% {
            transform: translate(0px, 0px)
        }
        100% {
            transform: translate(0px, 0px)
        }
    }

    #ev0tmtdx8qpf1:hover #ev0tmtdx8qpf13_tr {
        animation: ev0tmtdx8qpf13_tr__tr 3000ms linear 1 normal forwards
    }

    @keyframes ev0tmtdx8qpf13_tr__tr {
        0% {
            transform: translate(52.035000px, 48.750000px) rotate(20deg);
            animation-timing-function: cubic-bezier(0.165000, 0.840000, 0.440000, 1)
        }
        33.333333% {
            transform: translate(52.035000px, 48.750000px) rotate(0deg);
            animation-timing-function: cubic-bezier(0.165000, 0.840000, 0.440000, 1)
        }
        40% {
            transform: translate(52.035000px, 48.750000px) rotate(0deg)
        }
        100% {
            transform: translate(52.035000px, 48.750000px) rotate(0deg)
        }
    }

    #ev0tmtdx8qpf1:hover #ev0tmtdx8qpf13_ts {
        animation: ev0tmtdx8qpf13_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes ev0tmtdx8qpf13_ts__ts {
        0% {
            transform: scale(0, 0);
            animation-timing-function: cubic-bezier(0.165000, 0.840000, 0.440000, 1)
        }
        33.333333% {
            transform: scale(1, 1);
            animation-timing-function: cubic-bezier(0.165000, 0.840000, 0.440000, 1)
        }
        40% {
            transform: scale(1, 1)
        }
        43.333333% {
            transform: scale(0.900000, 0.900000)
        }
        46.666667% {
            transform: scale(1, 1)
        }
        50% {
            transform: scale(0.900000, 0.900000)
        }
        53.333333% {
            transform: scale(1, 1)
        }
        100% {
            transform: scale(1, 1)
        }
    }
</style>