<template>
  <svg id="e2wxnmzqyooc1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <defs>
      <clipPath id="e2wxnmzqyooc2">
        <circle id="e2wxnmzqyooc3" r="44" transform="matrix(1 0 0 1 52.59000000000000 46.97000000000000)" fill="none"
                stroke="none" stroke-width="1"/>
      </clipPath>
    </defs>
    <g id="e2wxnmzqyooc21" clip-path="url(#e2wxnmzqyooc2)">
      <g id="a2wxnmzqyooc22_to" transform="translate(-0.500000,0.130000)">
        <path id="e2wxnmzqyooc22"
              d="M48.280000,91L56.610000,91C75.870000,89.560000,82,79.720000,82,79.720000L82,25L22,25L22,79C29.159702,85.843839,38.419066,90.071859,48.280000,91Z"
              transform="translate(0,0)" fill="rgb(255,211,129)" stroke="none" stroke-width="1"/>
      </g>
    </g>
    <path id="e2wxnmzqyooc23"
          d="M52,93.130000C26.594902,93.130000,6,72.535098,6,47.130000C6,21.724902,26.594902,1.130000,52,1.130000C77.405098,1.130000,98,21.724902,98,47.130000C97.966945,72.521394,77.391394,93.096945,52,93.130000ZM52,5.130000C28.804041,5.130000,10,23.934041,10,47.130000C10,70.325959,28.804041,89.130000,52,89.130000C75.195959,89.130000,94,70.325959,94,47.130000C94,23.934041,75.195959,5.130000,52,5.130000Z"
          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    <path id="e2wxnmzqyooc24"
          d="M52,93.130000C26.594902,93.130000,6,72.535098,6,47.130000C6,21.724902,26.594902,1.130000,52,1.130000C77.405098,1.130000,98,21.724902,98,47.130000C97.966945,72.521394,77.391394,93.096945,52,93.130000ZM52,5.130000C28.804041,5.130000,10,23.934041,10,47.130000C10,70.325959,28.804041,89.130000,52,89.130000C75.195959,89.130000,94,70.325959,94,47.130000C94,23.934041,75.195959,5.130000,52,5.130000Z"
          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    <path id="e2wxnmzqyooc25"
          d="M68.060000,51L41.060000,51L41.060000,47L68.060000,47C70.545281,47,72.560000,44.985281,72.560000,42.500000C72.560000,40.014719,70.545281,38,68.060000,38L40.060000,38L40.060000,34L68.060000,34C72.754420,34,76.560000,37.805580,76.560000,42.500000C76.560000,47.194420,72.754420,51,68.060000,51Z"
          opacity="1" fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    <path id="e2wxnmzqyooc26"
          d="M58.890000,64L35.800000,64C31.105580,64,27.300000,60.194420,27.300000,55.500000C27.300000,50.805580,31.105580,47,35.800000,47L61.900000,47L61.900000,51L35.800000,51C33.314719,51,31.300000,53.014719,31.300000,55.500000C31.300000,57.985281,33.314719,60,35.800000,60L58.890000,60Z"
          opacity="1" fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    <path id="e2wxnmzqyooc27"
          d="M67.560000,77L39.560000,77L39.560000,73L67.560000,73C70.045281,73,72.060000,70.985281,72.060000,68.500000C72.060000,66.014719,70.045281,64,67.560000,64L47.560000,64L47.560000,60L67.560000,60C72.254420,60,76.060000,63.805580,76.060000,68.500000C76.060000,73.194420,72.254420,77,67.560000,77Z"
          opacity="1" fill="rgb(0,0,0)" stroke="none" stroke-width="1" stroke-opacity="0"/>
    <path id="e2wxnmzqyooc28"
          d="M40.060000,38C35.367864,37.994490,31.565510,34.192136,31.560000,29.500000L31.560000,17.770000L35.560000,17.770000L35.560000,29.470000C35.565499,31.953000,37.577000,33.964501,40.060000,33.970000Z"
          opacity="1" fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    <path id="e2wxnmzqyooc29"
          d="M35.560000,86.930000L31.560000,86.930000L31.560000,81.470000C31.565510,76.777864,35.367864,72.975510,40.060000,72.970000L40.060000,76.970000C37.577000,76.975499,35.565499,78.987000,35.560000,81.470000Z"
          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
  </svg>
</template>

<script>
export default {
  name: "MenuLeitfaden"
}
</script>

<style scoped>
#e2wxnmzqyooc1 {
  pointer-events: all
}

#e2wxnmzqyooc1:hover #a2wxnmzqyooc22_to {
  animation: a2wxnmzqyooc22_to__to 3000ms linear 1 normal forwards;
}

@keyframes a2wxnmzqyooc22_to__to {
  0% {
    transform: translate(-0.500000px, 68.130000px)
  }
  33.333333% {
    transform: translate(-0px, 0.500000px)
  }
  100% {
    transform: translate(-0px, 0.500000px)
  }
}

</style>