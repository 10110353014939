<template>
  <div class="header">
    <div class="header-label">
      <img :src="'./img/SAW_Logo_Website_' + $i18n.locale + '.svg'" @click="openTab">
    </div>
    <h1>
      {{ $t('home.title1') }}<br>
      <strong>{{ $t('home.title2') }}</strong>
    </h1>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    openTab() {
      var win = window.open(this.$t('home.link'), '_blank');
      win.focus();
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 200px;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @include tablet-down {
    padding-top: 25px;
    flex-direction: column;
  }

  @include mobile-down {
    height: auto;
    margin-bottom: 40px;
  }

  h1 {
    font-family: "HKSentiments-bold", sans-serif;
    font-size: 44px;
    letter-spacing: 0.05em;
    position: relative;
    z-index: 2;
    width: 70%;

    @include tablet-down {

      width: 100%;
      text-align: center;
      font-size: 25px;
      margin-bottom: 66px;
    }

    strong {
      font-family: "HKSentiments-black", sans-serif;
    }
  }
}

.header-label {
  width: calc(25% + 10px);
  position: relative;


  @include tablet-down {
    width: 50%;
  }

  img {
    width: 90%;
    cursor: pointer;

    @include tablet-down {
      width: 100%;
    }
  }

}
</style>
