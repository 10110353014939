<template>
    <svg id="ek7liqg8jnir1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 79.950000 90.100000" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">

        <g id="ek7liqg8jnir11">
            <path id="ek7liqg8jnir12"
                  d="M39.980000,66.580000C22.450000,66.580000,8.180000,52.320000,8.180000,34.780000C8.180000,17.250000,22.450000,2.980000,39.980000,2.980000C57.510000,2.980000,71.780000,17.250000,71.780000,34.780000C71.780000,52.310000,57.510000,66.580000,39.980000,66.580000ZM39.980000,6.980000C24.650000,6.980000,12.180000,19.450000,12.180000,34.780000C12.180000,50.110000,24.650000,62.580000,39.980000,62.580000C55.310000,62.580000,67.780000,50.110000,67.780000,34.780000C67.780000,19.450000,55.300000,6.980000,39.980000,6.980000Z"
                  fill="rgb(29,29,27)" stroke="none" stroke-width="1"/>
        </g>
        <g id="ek7liqg8jnir13">
            <polygon id="ek7liqg8jnir14" points="57.280000,39.080000 27.780000,23.370000 27.780000,54.800000"
                     fill="rgb(188,219,189)" stroke="none" stroke-width="1"/>
        </g>
        <g id="ek7liqg8jnir15">
            <g id="ek7liqg8jnir16_to" transform="translate(47,33)">
                <g id="ek7liqg8jnir16_tr" transform="rotate(359)">
                    <path id="ek7liqg8jnir16"
                          d="M29.970000,51.440000L29.970000,15.420000L63.780000,33.430000L29.970000,51.440000ZM33.970000,22.080000L33.970000,44.770000L55.270000,33.420000L33.970000,22.080000L33.970000,22.080000Z"
                          transform="translate(-46.555581,-33.719502)" fill="rgb(29,29,27)" stroke="none"
                          stroke-width="1"/>
                </g>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "MenuTippClip"
    }
</script>

<style scoped>
    #ek7liqg8jnir1 {
        pointer-events: all
    }

    #ek7liqg8jnir1:hover #ek7liqg8jnir14 {
        animation: ek7liqg8jnir14_c_o 1500ms linear 1 normal forwards
    }

    @keyframes ek7liqg8jnir14_c_o {
        0% {
            opacity: 0
        }
        33% {
            opacity: 0
        }
        40% {
            opacity: 1
        }
        100% {
            opacity: 1
        }
    }

    #ek7liqg8jnir1:hover #ek7liqg8jnir16_to {
        animation: ek7liqg8jnir16_to__to 3000ms linear 1 normal forwards
    }

    @keyframes ek7liqg8jnir16_to__to {
        0% {
            transform: translate(42.530000px, 34.780000px)
        }
        33.333333% {
            transform: translate(42.530000px, 34.780000px)
        }
        40% {
            transform: translate(47.136977px, 32.901860px)
        }
        100% {
            transform: translate(47.136977px, 32.901860px)
        }
    }

    #ek7liqg8jnir1:hover #ek7liqg8jnir16_tr {
        animation: ek7liqg8jnir16_tr__tr 3000ms linear 1 normal forwards
    }

    @keyframes ek7liqg8jnir16_tr__tr {
        0% {
            transform: rotate(359deg)
        }
        6.666667% {
            transform: rotate(359deg);
            animation-timing-function: cubic-bezier(0.175000, 0.885000, 0.320000, 1.275000)
        }
        26.666667% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(0deg)
        }
    }
</style>