<template>
  <svg id="at9gu9h1c7r71" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <g id="at9gu9h1c7r72">
      <g id="at9gu9h1c7r73_to" transform="translate(32,50)">
        <g id="at9gu9h1c7r73_ts" transform="scale(1,1)">
          <path id="at9gu9h1c7r73"
                d="M6.400000,47.200000C6.400000,60.400000,12.200000,72.200000,21.400000,80.300000L58.900000,80.300000L58.900000,19.900000L15.900000,19.900000C10,27.400000,6.400000,36.900000,6.400000,47.200000Z"
                transform="translate(-32.648438,-50.099609)" fill="rgb(204,204,204)" stroke="none" stroke-width="1"/>
        </g>
      </g>
    </g>
    <g id="at9gu9h1c7r74">

    </g>
    <g id="at9gu9h1c7r712">
      <path id="at9gu9h1c7r713"
            d="M50.400000,93.200000C25,93.200000,4.400000,72.600000,4.400000,47.200000C4.400000,21.800000,25,1.200000,50.400000,1.200000C75.800000,1.200000,96.400000,21.800000,96.400000,47.200000C96.400000,72.500000,75.800000,93.200000,50.400000,93.200000ZM50.400000,5.200000C27.200000,5.200000,8.400000,24,8.400000,47.200000C8.400000,70.400000,27.200000,89.200000,50.400000,89.200000C73.600000,89.200000,92.400000,70.400000,92.400000,47.200000C92.400000,24,73.600000,5.200000,50.400000,5.200000Z"
            fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    </g>
    <g id="at9gu9h1c7r714">
      <g id="at9gu9h1c7r715">
        <g id="at9gu9h1c7r716">
          <path id="at9gu9h1c7r717"
                d="M56,66.800000L35.200000,66.800000C32.300000,66.800000,27.900000,64.900000,27.900000,59.800000C27.900000,54.700000,32.300000,52.800000,35.200000,52.800000L65.200000,52.800000L65.200000,56.800000L35.200000,56.800000C34.400000,56.800000,31.900000,57.100000,31.900000,59.800000C31.900000,62.500000,34.400000,62.800000,35.200000,62.800000L56,62.800000L56,66.800000Z"
                fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
        </g>
      </g>
      <g id="at9gu9h1c7r718">
        <g id="at9gu9h1c7r719">
          <path id="at9gu9h1c7r720"
                d="M52.100000,56.800000L31.300000,56.800000C28.400000,56.800000,24,54.900000,24,49.800000C24,44.700000,28.400000,42.800000,31.300000,42.800000L65.100000,42.800000L65.100000,46.800000L31.300000,46.800000C30.500000,46.800000,28,47.100000,28,49.800000C28,52.500000,30.500000,52.800000,31.300000,52.800000L52.100000,52.800000L52.100000,56.800000Z"
                fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
        </g>
      </g>
      <g id="at9gu9h1c7r721">
        <path id="at9gu9h1c7r722"
              d="M88.600000,25.300000C86.800000,24.400000,84.900000,23.600000,83,22.700000C70.600000,17.400000,77.700000,20.300000,71.800000,17.800000C69.200000,16.700000,66.200000,16.100000,64.700000,18.700000C63.100000,21.500000,64.600000,24.700000,67.600000,26.900000C69.500000,28.300000,80.900000,34.800000,80.900000,34.800000L34.600000,34.800000C34.600000,34.800000,29.300000,34.800000,29.300000,39.800000C29.300000,44.800000,34.600000,44.800000,34.600000,44.800000L55.400000,44.800000M65.100000,64.800000L44.600000,64.800000C44.600000,64.800000,39.300000,64.800000,39.300000,69.800000C39.300000,74.800000,44.600000,74.800000,44.600000,74.800000L83.900000,74.800000"
              fill="none" stroke="rgb(0,0,0)" stroke-width="4" stroke-linejoin="bevel"/>
      </g>
    </g>
    <g id="at9gu9h1c7r723">
      <path id="at9gu9h1c7r724"
            d="M50.400000,93.200000C25,93.200000,4.400000,72.600000,4.400000,47.200000C4.400000,21.800000,25,1.200000,50.400000,1.200000C75.800000,1.200000,96.400000,21.800000,96.400000,47.200000C96.400000,72.500000,75.800000,93.200000,50.400000,93.200000ZM50.400000,5.200000C27.200000,5.200000,8.400000,24,8.400000,47.200000C8.400000,70.400000,27.200000,89.200000,50.400000,89.200000C73.600000,89.200000,92.400000,70.400000,92.400000,47.200000C92.400000,24,73.600000,5.200000,50.400000,5.200000Z"
            fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuHandout"
}
</script>

<style scoped>

#at9gu9h1c7r71 {
  pointer-events: all
}

#at9gu9h1c7r71:hover #at9gu9h1c7r73_to {
  animation: at9gu9h1c7r73_to__to 3000ms linear 1 normal forwards
}

@keyframes at9gu9h1c7r73_to__to {
  0% {
    transform: translate(8px, 50px);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  20% {
    transform: translate(32.348437px, 49.799609px)
  }
  100% {
    transform: translate(32.348437px, 49.799609px)
  }
}

#at9gu9h1c7r71:hover #at9gu9h1c7r73_ts {
  animation: at9gu9h1c7r73_ts__ts 3000ms linear 1 normal forwards
}

@keyframes at9gu9h1c7r73_ts__ts {
  0% {
    transform: scale(0, 0.530000);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  20% {
    transform: scale(0.991517, 1)
  }
  100% {
    transform: scale(0.991517, 1)
  }
}

</style>
