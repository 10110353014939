<template>
  <div class="lang-switch">
    <div class="icon lang-de" :class="{active: $i18n.locale == 'de'}" @click="switchTo('de')">
      DE
    </div>
    <div class="icon lang-fr" :class="{active: $i18n.locale == 'fr'}" @click="switchTo('fr')">
      FR
    </div>
    <div class="icon lang-it" :class="{active: $i18n.locale == 'it'}" @click="switchTo('it')">
      IT
    </div>
  </div>
</template>

<script>
export default {
name: "LanguageSwitch",
  methods: {
    switchTo(lang) {
      //alert("Die Sprache würde jetzt gewechselt werden.")
      this.$root.$i18n.locale = lang;
    }
  },
}
</script>

<style lang="scss" scoped>

.lang-switch {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .icon {
    padding: 4px;
    padding-top: 12px;
    cursor: pointer;
    font-family: "HKSentiments-black", sans-serif;
    box-sizing: border-box;
    border-bottom: white solid 3px;

    &.active {
      border-bottom: black solid 3px;
    }

    &:hover {
      border-bottom: black solid 3px;
    }
  }
}

</style>