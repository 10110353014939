<template>
  <div class="footer">
    <div class="footer-menu">
      <router-link to="/intro">
        <MenuWrapper comp-name="MenuIntro" :men-name="$t('footer.intro')"></MenuWrapper>
      </router-link>

      <router-link to="/leitfaden">
        <MenuWrapper comp-name="MenuLeitfaden" :men-name="$t('footer.guide')"></MenuWrapper>
      </router-link>

      <router-link to="/handout">
        <MenuWrapper comp-name="MenuHandout" men-name="Handout"></MenuWrapper>
      </router-link>

      <router-link to="/grundlagen">
        <MenuWrapper comp-name="MenuGrundlagen" :men-name="$t('footer.right')"></MenuWrapper>
      </router-link>

      <a :href="'/quiz/index.html#/' + $i18n.locale + '/start'" target="_blank">
        <MenuWrapper comp-name="MenuQuiz" :men-name="$t('footer.quiz')"></MenuWrapper>
      </a>

      <!--<a href="#" @click.prevent="quiz" target="_blank">
        <MenuWrapper comp-name="MenuQuiz" :men-name="$t('footer.quiz')"></MenuWrapper>
      </a>-->

      <router-link to="/feedback">
        <MenuWrapper comp-name="MenuFeedback" men-name="Feedback"></MenuWrapper>
      </router-link>

      <router-link to="/suche">
        <MenuWrapper comp-name="MenuSuche" :men-name="$t('footer.search')"></MenuWrapper>
      </router-link>
    </div>
    <div class="footer-credits">
      <div class="footer-logo">
        <img src="/EKAS_de.png">
      </div>
      <div class="footer-credits-text">
        Copyright © 2024 SAFE AT WORK, All rights reserved / <router-link to="/impressum">{{$t('footer.impressum')}}</router-link> / <router-link to="/datenschutz">{{$t('footer.datenschutz')}}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import MenuWrapper from "./animatedMenus/MenuWrapper";
import TheCookieBanner from "@/components/TheCookieBanner";

export default {
  name: "Footer",
  components: {
    TheCookieBanner,
    MenuWrapper
  },
  methods: {
    quiz() {
      alert("Hier wird das Quiz geöffnet")
    }
  },
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  height: 150px;
  border-top: black solid 5px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;

  @include desktop-large-down {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .footer-menu {
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 20px;

    @include desktop-large-down {
    }

    @include desktop-large-down {
      justify-content: center;
      width: 100%;
      padding: 25px;
      flex-wrap: wrap;
    }

    @include mobile-down {
      flex-wrap: wrap;
    }

    & > a {
      margin-right: 45px;
      text-decoration: none;

      @include mobile-down {
        margin-right: 0;
      }
    }

    svg {
      width: 100px;
      cursor: pointer;

      @include desktop-large-down {
        margin-right: 10px;
      }

      @include desktop-large-down {
        height: auto;
        width: 150px;
      }

      @include mobile-down {
        height: auto;
        width: 100px;
        margin: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-credits {
    font-size: 12px;
    padding: 22px;
  }

  .footer-logo {
    img {
      width: 100%;
      max-width: 400px;
      min-width: 200px;
      padding-bottom: 10px;
    }
  }
}
</style>
