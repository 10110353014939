<template>
  <svg id="enh0gtkklcqh1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <g id="enh0gtkklcqh2">
      <g id="enh0gtkklcqh3">
        <g id="enh0gtkklcqh4">
          <g id="enh0gtkklcqh5_tr" transform="translate(51.300000,19.668518) rotate(0)">
            <polygon id="enh0gtkklcqh5"
                     points="12.700000,9.300000 12.700000,71.300000 51.300000,84.900000 51.300000,22.800000"
                     transform="translate(-51.300000,-19.668518)" fill="rgb(167,198,215)" stroke="none"
                     stroke-width="1"/>
          </g>
        </g>
        <g id="enh0gtkklcqh6">
          <g id="enh0gtkklcqh7_tr" transform="translate(51.500000,19.668518) rotate(0)">
            <polygon id="enh0gtkklcqh7" points="90,9.300000 90,71.300000 51.300000,84.900000 51.300000,22.800000"
                     transform="translate(-51.500000,-19.668518)" fill="rgb(167,198,215)" stroke="none"
                     stroke-width="1"/>
          </g>
        </g>
      </g>
      <g id="enh0gtkklcqh8_tr" transform="translate(51.300000,41.484259) rotate(0)">
        <g id="enh0gtkklcqh8" transform="translate(-51.300000,-41.484259)">
          <g id="enh0gtkklcqh9">
            <path id="enh0gtkklcqh10"
                  d="M62.100000,63.300000L60.200000,59.800000C60.400000,59.700000,65,57.100000,65,52.200000C65,48.200000,60,46.400000,53.100000,44.300000C52.400000,44.100000,51.600000,43.900000,50.900000,43.600000C45.600000,41.900000,36.400000,38.300000,34.200000,32.500000C33.400000,30.300000,33.600000,27.900000,35,25.600000C41.600000,14.700000,64.100000,22.100000,65.100000,22.400000L63.800000,26.200000C58.100000,24.300000,42.500000,20.900000,38.400000,27.700000C37.700000,28.900000,37.500000,30,37.900000,31.100000C39,34.100000,44.400000,37.400000,52,39.700000C52.800000,39.900000,53.500000,40.100000,54.200000,40.400000C60.800000,42.400000,69,44.800000,69,52.100000C69,59.500000,62.400000,63.200000,62.100000,63.300000Z"
                  fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
          </g>
          <g id="enh0gtkklcqh11">
            <path id="enh0gtkklcqh12"
                  d="M53.700000,78.500000C46,78.500000,38.200000,75.900000,37.600000,75.800000L38.900000,72C44.600000,73.900000,60.200000,77.300000,64.300000,70.500000C65,69.300000,65.200000,68.200000,64.800000,67.100000C63.700000,64.100000,58.300000,60.800000,50.700000,58.500000C49.900000,58.300000,49.200000,58.100000,48.500000,57.800000C41.900000,55.800000,33.700000,53.400000,33.700000,46.100000C33.700000,38.800000,40.300000,35.100000,40.500000,35L42.400000,38.500000C42.200000,38.600000,37.600000,41.200000,37.600000,46.100000C37.600000,50.100000,42.600000,51.900000,49.600000,54C50.300000,54.200000,51.100000,54.400000,51.800000,54.700000C57.100000,56.400000,66.300000,60,68.500000,65.800000C69.300000,68,69.100000,70.400000,67.700000,72.700000C64.900000,77.200000,59.300000,78.500000,53.700000,78.500000Z"
                  fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
          </g>
        </g>
      </g>
      <g id="enh0gtkklcqh13">
        <path id="enh0gtkklcqh14"
              d="M51.300000,95.100000C25.900000,95.100000,5.300000,74.500000,5.300000,49.100000C5.300000,23.700000,25.900000,3.100000,51.300000,3.100000C76.700000,3.100000,97.300000,23.700000,97.300000,49.100000C97.300000,74.500000,76.700000,95.100000,51.300000,95.100000ZM51.300000,7.100000C28.100000,7.100000,9.300000,25.900000,9.300000,49.100000C9.300000,72.300000,28.100000,91.100000,51.300000,91.100000C74.500000,91.100000,93.300000,72.300000,93.300000,49.100000C93.300000,26,74.500000,7.100000,51.300000,7.100000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuGrundlagen"
}
</script>

<style scoped>
#enh0gtkklcqh1:hover #enh0gtkklcqh5_tr {
  animation: enh0gtkklcqh5_tr__tr 3000ms linear 1 normal forwards
}

@keyframes enh0gtkklcqh5_tr__tr {
  0% {
    transform: translate(51.300000px, 19.668518px) rotateY(90deg);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  23.333333% {
    transform: translate(51.300000px, 19.668518px) rotateY(0deg)
  }
  100% {
    transform: translate(51.300000px, 19.668518px) rotateY(0deg)
  }
}

#enh0gtkklcqh1:hover #enh0gtkklcqh7_tr {
  animation: enh0gtkklcqh7_tr__tr 3000ms linear 1 normal forwards
}

@keyframes enh0gtkklcqh7_tr__tr {
  0% {
    transform: translate(51.500000px, 19.668518px) rotateY(90deg);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  23.333333% {
    transform: translate(51.500000px, 19.668518px) rotateY(0deg)
  }
  100% {
    transform: translate(51.500000px, 19.668518px) rotateY(0deg)
  }
}

#enh0gtkklcqh1:hover #enh0gtkklcqh8_tr {
  animation: enh0gtkklcqh8_tr__tr 3000ms linear 1 normal forwards
}

@keyframes enh0gtkklcqh8_tr__tr {
  0% {
    transform: translate(51.300000px, 41.484259px) rotateY(90deg)
  }
  16.666667% {
    transform: translate(51.300000px, 41.484259px) rotateY(90deg);
    animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
  }
  33.333333% {
    transform: translate(51.300000px, 41.484259px) rotateY(0deg)
  }
  100% {
    transform: translate(51.300000px, 41.484259px) rotate(0deg)
  }
}

</style>