<template>
  <svg id="evosaks38vds1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
       viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
    <g id="evosaks38vds2"/>
    <g id="evosaks38vds3">
      <g id="evosaks38vds4_tr" transform="translate(51.500000,49.250000) rotate(0)">
        <g id="evosaks38vds4" transform="translate(-51.500000,-49.250000)">
          <g id="evosaks38vds5">
            <rect id="evosaks38vds6" width="54.300000" height="54.300000" rx="0" ry="0"
                  transform="matrix(1 0 0 1 24.34999999999999 22.10000000000001)" fill="rgb(186,217,187)" stroke="none"
                  stroke-width="1"/>
          </g>
          <g id="evosaks38vds7">
            <polygon id="evosaks38vds8" points="51.500000,86.200000 57.400000,74.300000 45.600000,74.300000"
                     fill="rgb(186,217,187)" stroke="none" stroke-width="1"/>
          </g>
          <g id="evosaks38vds9">
            <path id="evosaks38vds10"
                  d="M53.500000,68.200000L49.500000,68.200000L49.500000,56.300000C49.500000,55.200000,50.400000,54.300000,51.500000,54.300000C60.600000,54.300000,68,46.900000,68,37.800000C68,28.700000,60.600000,21.300000,51.500000,21.300000C42.400000,21.300000,35,28.700000,35,37.800000L31,37.800000C31,26.500000,40.200000,17.300000,51.500000,17.300000C62.800000,17.300000,72,26.600000,72,37.900000C72,48.500000,63.900000,57.300000,53.500000,58.300000L53.500000,68.200000Z"
                  fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
          </g>
          <g id="evosaks38vds11">
            <circle id="evosaks38vds12" r="2.900000" transform="matrix(1 0 0 1 51.50000000000000 73.50000000000000)"
                    fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
          </g>
        </g>
      </g>
      <g id="evosaks38vds13">
        <path id="evosaks38vds14"
              d="M51.500000,94.800000C26.100000,94.800000,5.500000,74.200000,5.500000,48.800000C5.500000,23.400000,26.100000,2.800000,51.500000,2.800000C76.900000,2.800000,97.500000,23.400000,97.500000,48.800000C97.500000,74.200000,76.900000,94.800000,51.500000,94.800000ZM51.500000,6.800000C28.300000,6.800000,9.500000,25.600000,9.500000,48.800000C9.500000,72,28.300000,90.800000,51.500000,90.800000C74.700000,90.800000,93.500000,72,93.500000,48.800000C93.500000,25.600000,74.700000,6.800000,51.500000,6.800000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MenuAnleitung"
}
</script>

<style scoped>

#evosaks38vds1:hover #evosaks38vds4_tr {
  animation: evosaks38vds4_tr__tr 3000ms linear 1 normal forwards
}

@keyframes evosaks38vds4_tr__tr {
  0% {
    transform: translate(51.500000px, 49.250000px) rotateY(0deg);
    animation-timing-function: cubic-bezier(0.175000, 0.885000, 0.320000, 1.275000)
  }
  33.333333% {
    transform: translate(51.500000px, 49.250000px) rotateY(360deg)
  }
  100% {
    transform: translate(51.500000px, 49.250000px) rotateY(360deg)
  }
}

</style>