<template>
    <svg id="e3nl0ho0gup31" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 79.950000 90.100000" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <g id="e3nl0ho0gup313">
            <g id="a3nl0ho0gup314_to" transform="translate(2.290000,7.270000)">
                <path id="e3nl0ho0gup314"
                      d="M59.670000,31.190000C59.670000,37.940000,54.190000,43.420000,47.440000,43.420000C40.690000,43.420000,35.210000,37.950000,35.210000,31.190000C35.210000,24.430000,40.680000,18.960000,47.440000,18.960000C54.190000,18.970000,59.670000,24.440000,59.670000,31.190000"
                      transform="translate(0,0)" fill="rgb(192,155,173)" stroke="none" stroke-width="1"/>
            </g>
        </g>
        <g id="e3nl0ho0gup315">
            <path id="e3nl0ho0gup316"
                  d="M39.020000,67.420000C21.490000,67.420000,7.220000,53.160000,7.220000,35.620000C7.220000,18.090000,21.490000,3.820000,39.020000,3.820000C56.550000,3.820000,70.820000,18.090000,70.820000,35.620000C70.820000,53.150000,56.560000,67.420000,39.020000,67.420000ZM39.020000,7.820000C23.690000,7.820000,11.220000,20.290000,11.220000,35.620000C11.220000,50.950000,23.690000,63.420000,39.020000,63.420000C54.350000,63.420000,66.820000,50.950000,66.820000,35.620000C66.820000,20.290000,54.350000,7.820000,39.020000,7.820000Z"
                  fill="rgb(29,29,27)" stroke="none" stroke-width="1"/>
        </g>
        <g id="e3nl0ho0gup317">
            <g id="e3nl0ho0gup318_ts" transform="translate(39.025000,35.620000) scale(1,1)">
                <path id="e3nl0ho0gup318"
                      d="M39.060000,50.690000C29.200000,50.690000,19.600000,46.120000,10.520000,37.080000L9.220000,35.790000L10.400000,34.380000C10.850000,33.850000,21.540000,21.300000,37.580000,20.600000C47.920000,20.140000,58.010000,24.740000,67.530000,34.260000L68.830000,35.560000L67.650000,36.960000C67.200000,37.490000,56.510000,49.970000,40.470000,50.670000C39.990000,50.670000,39.530000,50.690000,39.060000,50.690000ZM14.740000,35.590000C22.990000,43.310000,31.560000,47.030000,40.250000,46.660000C51.560000,46.180000,60.170000,38.810000,63.300000,35.710000C55.050000,27.950000,46.480000,24.210000,37.790000,24.580000C26.480000,25.060000,17.870000,32.480000,14.740000,35.590000Z"
                      transform="translate(-39.025000,-35.620000)" fill="rgb(29,29,27)" stroke="none" stroke-width="1"/>
            </g>
        </g>
    </svg>

</template>

<script>
    export default {
        name: "MenuBlackstory"
    }
</script>

<style scoped>
    #e3nl0ho0gup31 {
        pointer-events: all
    }

    #e3nl0ho0gup31:hover #a3nl0ho0gup314_to {
        animation: a3nl0ho0gup314_to__to 3000ms linear 1 normal forwards
    }

    @keyframes a3nl0ho0gup314_to__to {
        0% {
            transform: translate(2.290000px, 7.270000px);
            opacity: 1;
        }
        6.666667% {
            transform: translate(2.290000px, 7.270000px);
            opacity: 1;
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        26.666667% {
            transform: translate(-22.160000px, 4.439312px);
            opacity: 1;
            animation-timing-function: cubic-bezier(0.420000, 0, 1, 1)
        }
        46.666667% {
            opacity: 1;
            transform: translate(0px, 0px)
        }
        100% {
            opacity: 1;
            transform: translate(0px, 0px)
        }
    }

    #e3nl0ho0gup31:hover #e3nl0ho0gup318_ts {
        animation: e3nl0ho0gup318_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes e3nl0ho0gup318_ts__ts {
        0% {
            transform: translate(39.025000px, 35.620000px) scale(1, 1)
        }
        13.333333% {
            transform: translate(39.025000px, 35.620000px) scale(1, 1)
        }
        20% {
            transform: translate(39.025000px, 35.620000px) scale(1, 0)
        }
        26.666667% {
            transform: translate(39.025000px, 35.620000px) scale(1, 1)
        }
        100% {
            transform: translate(39.025000px, 35.620000px) scale(1, 1)
        }
    }
</style>