<template>
    <svg id="equewp38nbbd1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <defs>
            <clipPath id="equewp38nbbd2">
                <circle id="equewp38nbbd3" r="44.350000" transform="matrix(1 0 0 1 52.12000000000000 47.03000000000000)"
                        fill="none" stroke="none" stroke-width="1"/>
            </clipPath>
        </defs>
        <g id="equewp38nbbd20_to" transform="translate(52.125000,20)">
            <g id="equewp38nbbd20_ts" transform="">
                <polygon id="equewp38nbbd20"
                         points="13.390000,6.540000 13.390000,68.560000 52,82.140000 52,20.110000 13.390000,6.540000"
                         transform="translate(-52.125000,-19.963574)" fill="rgb(186,217,187)" stroke="none"
                         stroke-width="1"/>
            </g>
        </g>
        <g id="equewp38nbbd21_to" transform="translate(52,20)">
            <g id="equewp38nbbd21_ts" transform="">
                <polygon id="equewp38nbbd21"
                         points="90.610000,6.540000 90.610000,68.560000 52,82.140000 52,20.110000 90.610000,6.540000"
                         transform="translate(-52,-19.739264)" fill="rgb(186,217,187)" stroke="none" stroke-width="1"/>
            </g>
        </g>
        <path id="equewp38nbbd22"
              d="M52,93C26.594902,93,6,72.405098,6,47C6,21.594902,26.594902,1,52,1C77.405098,1,98,21.594902,98,47C97.966945,72.391394,77.391394,92.966945,52,93ZM52,5C28.804041,5,10,23.804041,10,47C10,70.195959,28.804041,89,52,89C75.195959,89,94,70.195959,94,47C94,23.804041,75.195959,5,52,5Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
        <g id="equewp38nbbd23" clip-path="url(#equewp38nbbd2)">
            <g id="equewp38nbbd24_to" transform="translate(0,10.859375)">
                <g id="equewp38nbbd24" transform="translate(0,0)">
                    <path id="equewp38nbbd25"
                          d="M27,82.140000L23,82.140000L23,36.410000C23,32.870000,25.250000,27.610000,31.440000,27.610000C37.630000,27.610000,39.880000,32.870000,39.880000,36.410000L39.880000,62.860000L35.880000,62.860000L35.880000,36.410000C35.880000,35.620000,35.610000,31.610000,31.440000,31.610000C27.270000,31.610000,27,35.620000,27,36.420000Z"
                          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                    <path id="equewp38nbbd26"
                          d="M53.580000,62.710000L49.580000,62.710000L49.580000,51.300000C49.580000,50.530000,49.310000,46.600000,45.140000,46.600000C40.970000,46.600000,40.710000,50.530000,40.700000,51.310000L40.700000,62.710000L36.700000,62.710000L36.700000,51.300000C36.700000,47.800000,38.950000,42.600000,45.140000,42.600000C51.330000,42.600000,53.580000,47.800000,53.580000,51.300000Z"
                          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                    <path id="equewp38nbbd27"
                          d="M81,82.140000L77,82.140000L77,51.380000C77,50.590000,76.730000,46.600000,72.550000,46.600000C68.370000,46.600000,68.120000,50.600000,68.110000,51.390000L68.110000,62.920000L64.110000,62.920000L64.110000,51.380000C64.110000,47.850000,66.360000,42.600000,72.550000,42.600000C78.740000,42.600000,81,47.850000,81,51.380000Z"
                          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                    <path id="equewp38nbbd28"
                          d="M67.290000,62.710000L63.290000,62.710000L63.290000,51.300000C63.290000,50.530000,63.020000,46.600000,58.850000,46.600000C54.680000,46.600000,54.420000,50.530000,54.410000,51.310000L54.410000,62.710000L50.410000,62.710000L50.410000,51.300000C50.410000,47.800000,52.660000,42.600000,58.850000,42.600000C65.040000,42.600000,67.290000,47.800000,67.290000,51.300000Z"
                          fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "MenuAnleitung"
    }
</script>

<style scoped>
    #equewp38nbbd1 {
        pointer-events: all
    }

    #equewp38nbbd1:hover #equewp38nbbd20_to {
        animation: equewp38nbbd20_to__to 3000ms linear 1 normal forwards
    }

    @keyframes equewp38nbbd20_to__to {
        0% {
            transform: translate(52.125000px, 10px)
        }
        26.666667% {
            transform: translate(34.100000px, 24.838859px)
        }
        33.333333% {
            transform: translate(52.125000px, 19.963574px)
        }
        100% {
            transform: translate(52.125000px, 19.963574px)
        }
    }

    #equewp38nbbd1:hover #equewp38nbbd20_ts {
        animation: equewp38nbbd20_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes equewp38nbbd20_ts__ts {
        0% {
            transform: scale(0, 0);
            animation-timing-function: cubic-bezier(0.250000, 0.460000, 0.450000, 0.940000)
        }
        33.333333% {
            transform: scale(1, 1)
        }
        100% {
            transform: scale(1, 1)
        }
    }

    #equewp38nbbd1:hover #equewp38nbbd21_to {
        animation: equewp38nbbd21_to__to 3000ms linear 1 normal forwards
    }

    @keyframes equewp38nbbd21_to__to {
        0% {
            transform: translate(52px, 10px)
        }
        26.666667% {
            transform: translate(69.000358px, 24.659411px)
        }
        33.333333% {
            transform: translate(52px, 19.739264px)
        }
        100% {
            transform: translate(52px, 19.739264px)
        }
    }

    #equewp38nbbd1:hover #equewp38nbbd21_ts {
        animation: equewp38nbbd21_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes equewp38nbbd21_ts__ts {
        0% {
            transform: scale(0, 0);
            animation-timing-function: cubic-bezier(0.250000, 0.460000, 0.450000, 0.940000)
        }
        33.333333% {
            transform: scale(1, 1)
        }
        100% {
            transform: scale(1, 1)
        }
    }

    #equewp38nbbd1:hover #equewp38nbbd24_to {
        animation: equewp38nbbd24_to__to 3000ms linear 1 normal forwards
    }

    @keyframes equewp38nbbd24_to__to {
        0% {
            transform: translate(0px, 10.859375px)
        }
        23.333333% {
            transform: translate(0px, 10.859375px)
        }
        33.333333% {
            transform: translate(0px, 0px)
        }
        36.666667% {
            transform: translate(0px, 0px)
        }
        40% {
            transform: translate(0px, 10.859375px)
        }
        43.333333% {
            transform: translate(0px, 0px)
        }
        46.666667% {
            transform: translate(0px, 10.859375px)
        }
        50% {
            transform: translate(0px, 0px)
        }
        100% {
            transform: translate(0px, 0px)
        }
    }
</style>