<template>
    <svg id="egcxk09fy4481" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 0 103 117" shape-rendering="geometricPrecision" text-rendering="geometricPrecision">
        <defs>
            <clipPath id="egcxk09fy4482">
                <circle id="egcxk09fy4483" r="28.040000" transform="matrix(1 0 0 1 51.50000000000000 47.13000000000000)"
                        fill="none" stroke="none" stroke-width="1"/>
            </clipPath>
            <clipPath id="egcxk09fy4484">
                <circle id="egcxk09fy4485" r="43.430000" transform="matrix(1 0 0 1 51.50000000000000 47.13000000000000)"
                        fill="none" stroke="none" stroke-width="1"/>
            </clipPath>
        </defs>
        <rect id="egcxk09fy44816" width="88" height="11.616700" rx="0" ry="0"
              transform="matrix(0.70710676908493 -0.70710676908493 0.70710676908493 0.70710676908493 16.78450569868088 74.13079724073411)"
              fill="rgb(191,153,179)" stroke="none" stroke-width="1"/>
        <g id="egcxk09fy44817" clip-path="url(#egcxk09fy4484)">
            <g id="egcxk09fy44818_to" transform="">
                <g id="egcxk09fy44818_ts" transform="scale(1,1)">
                    <g id="egcxk09fy44818" transform="translate(0,0)" clip-path="url(#egcxk09fy4482)">
                        <g id="egcxk09fy44819_to" transform="">
                            <g id="egcxk09fy44819" transform="translate(0,0)">
                                <rect id="egcxk09fy44820" width="53.966700" height="22.983300" rx="0" ry="0"
                                      transform="matrix(0.70710676908493 -0.70710676908493 0.70710676908493 0.70710676908493 7.73665167570114 75.63536794543268)"
                                      fill="rgb(191,153,179)" stroke="none" stroke-width="1"/>
                                <rect id="egcxk09fy44821" width="53.966700" height="22.983300" rx="0" ry="0"
                                      transform="matrix(0.70710676908493 -0.70710676908493 0.70710676908493 0.70710676908493 42.87664999246599 40.50404558658600)"
                                      fill="rgb(191,153,179)" stroke="none" stroke-width="1"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
            <g id="egcxk09fy44822_to" transform="">
                <g id="egcxk09fy44822_ts" transform="scale(1,1)">
                    <g id="egcxk09fy44822" transform="translate(0,0)">
                        <path id="egcxk09fy44823"
                              d="M52,77.190000C35.398320,77.190000,21.940000,63.731680,21.940000,47.130000C21.940000,30.528320,35.398320,17.070000,52,17.070000C68.601680,17.070000,82.060000,30.528320,82.060000,47.130000C82.043463,63.724825,68.594825,77.173463,52,77.190000ZM52,21.070000C37.607459,21.070000,25.940000,32.737459,25.940000,47.130000C25.940000,61.522541,37.607459,73.190000,52,73.190000C66.392541,73.190000,78.060000,61.522541,78.060000,47.130000C78.043468,32.744313,66.385687,21.086532,52,21.070000Z"
                              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                        <rect id="egcxk09fy44824" width="4" height="17.633300" rx="0" ry="0"
                              transform="matrix(0.70710676908493 -0.70710676908493 0.70710676908493 0.70710676908493 67.62728709042074 71.19371977627279)"
                              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                        <rect id="egcxk09fy44825" width="4" height="17.633300" rx="0" ry="0"
                              transform="matrix(0.70710676908493 -0.70710676908493 0.70710676908493 0.70710676908493 73.20728701412679 65.60241128504278)"
                              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
                    </g>
                </g>
            </g>
        </g>
        <path id="egcxk09fy44826"
              d="M52,93.130000C26.594902,93.130000,6,72.535098,6,47.130000C6,21.724902,26.594902,1.130000,52,1.130000C77.405098,1.130000,98,21.724902,98,47.130000C97.966945,72.521394,77.391394,93.096945,52,93.130000ZM52,5.130000C28.804041,5.130000,10,23.934041,10,47.130000C10,70.325959,28.804041,89.130000,52,89.130000C75.195959,89.130000,94,70.325959,94,47.130000C94,23.934041,75.195959,5.130000,52,5.130000Z"
              fill="rgb(0,0,0)" stroke="none" stroke-width="1"/>
    </svg>
</template>

<script>
    export default {
        name: "MenuSuche"
    }
</script>

<style scoped>
    #egcxk09fy4481 {
        pointer-events: all
    }

    #egcxk09fy4481:hover #egcxk09fy44818_to {
        animation: egcxk09fy44818_to__to 3000ms linear 1 normal forwards
    }

    @keyframes egcxk09fy44818_to__to {
        0% {
            transform: translate(70.903036px, -19.262251px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        23.333333% {
            transform: translate(35.543527px, -22.307293px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        46.666667% {
            transform: translate(0px, 0px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        53.333333% {
            transform: translate(-4.876476px, -5.367509px)
        }
        60% {
            transform: translate(0px, 0px)
        }
        100% {
            transform: translate(0px, 0px)
        }
    }

    #egcxk09fy4481:hover #egcxk09fy44818_ts {
        animation: egcxk09fy44818_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes egcxk09fy44818_ts__ts {
        0% {
            transform: scale(1, 1)
        }
        46.666667% {
            transform: scale(1, 1)
        }
        53.333333% {
            transform: scale(1.100000, 1.100000)
        }
        60% {
            transform: scale(1, 1)
        }
        100% {
            transform: scale(1, 1)
        }
    }

    #egcxk09fy4481:hover #egcxk09fy44819_to {
        animation: egcxk09fy44819_to__to 3000ms linear 1 normal forwards
    }

    @keyframes egcxk09fy44819_to__to {
        0% {
            transform: translate(-15.265806px, -17.301989px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        23.333333% {
            transform: translate(-1.151399px, -4.688480px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        46.666667% {
            transform: translate(3.598867px, -2.343826px)
        }
        100% {
            transform: translate(3.598867px, -2.343826px)
        }
    }

    #egcxk09fy4481:hover #egcxk09fy44822_to {
        animation: egcxk09fy44822_to__to 3000ms linear 1 normal forwards
    }

    @keyframes egcxk09fy44822_to__to {
        0% {
            transform: translate(71.415620px, -19.276830px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        23.333333% {
            transform: translate(34.904713px, -22.321872px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        46.666667% {
            transform: translate(0px, 0px);
            animation-timing-function: cubic-bezier(0, 0, 0.580000, 1)
        }
        53.333333% {
            transform: translate(-3.899042px, -4.488315px)
        }
        60% {
            transform: translate(0px, 0px)
        }
        100% {
            transform: translate(0px, 0px)
        }
    }

    #egcxk09fy4481:hover #egcxk09fy44822_ts {
        animation: egcxk09fy44822_ts__ts 3000ms linear 1 normal forwards
    }

    @keyframes egcxk09fy44822_ts__ts {
        0% {
            transform: scale(1, 1)
        }
        46.666667% {
            transform: scale(1, 1)
        }
        53.333333% {
            transform: scale(1.100000, 1.100000)
        }
        60% {
            transform: scale(1, 1)
        }
        100% {
            transform: scale(1, 1)
        }
    }
</style>