import Vue from 'vue'
import VueI18n from 'vue-i18n'
import de from './de.json'
import fr from './fr.json'
import it from './it.json'

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
    de: {
        ...de
    },
    fr: {
        ...fr
    },
    it: {
        ...it
    }

}


// Create VueI18n instance with options
export default new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
})